import { useState, useEffect, useRef } from 'react'
import { fetchApi } from '../../../utilities/fetchApi'
import { fetchDatabase } from '../../../utilities/fetchDatabase'
import ProductSelection from './ProductSelection'
import CurrentProducts from './CurrentProducts'

const ListContainer = ({ handleAddProduct, handleRemoveProduct, currentDeal, queryString }) => {
  const [searchResults, setSearchResults] = useState([])
  const [productOrCollection, setProductOrCollection] = useState('products')

  const allProducts = useRef([])
  const allCollections = useRef([])
  const otherDealProducts = useRef([])

  // set other deal products
  useEffect(() => {
    async function fetchAllProducts() {
      if (queryString !== null) {
        const response = await fetchDatabase({}, 'GET', `/database/all-products/?${queryString}`)
        otherDealProducts.current = response
      }
    }
    fetchAllProducts()
  }, [queryString])

  // switch between product or collection tabs
  const switchProductOrCollection = switchTo => {
    setProductOrCollection(switchTo)
  }

  // change results list depending on products or collection view
  useEffect(() => {
    if (productOrCollection === 'products') {
      setSearchResults(allProducts.current)
    } else if (productOrCollection === 'collection') {
      setSearchResults(allCollections.current)
    }
  }, [productOrCollection])

  // set all products
  useEffect(() => {
    async function getAllProductResults() {
      if (queryString !== null) {
        const results = await fetchApi({
          query: 'products_query',
          queryString: `/?${queryString}`,
        })
        allProducts.current = results
        setSearchResults(results)
      }
    }

    getAllProductResults()
  }, [queryString])

  // set all collections
  useEffect(() => {
    async function getAllCollectionResults() {
      if (queryString !== null) {
        const results = await fetchApi({
          query: 'collection_query',
          queryString: `/?${queryString}`,
        })
        allCollections.current = results
      }
    }

    getAllCollectionResults()
  }, [queryString])

  // add product in deal status and then send up to newDeal
  function addProduct(product) {
    handleAddProduct(product)
  }

  async function addCollection(collection) {
    // fetch collection products using API
    if (queryString !== null) {
      const results = await fetchApi({
        query: 'collection_products',
        searchTerm: collection.id,
        queryString: `/?${queryString}`,
      })
      // pass array of products up to NewDeal
      handleAddProduct(results, [...currentDeal.products, ...otherDealProducts.current])
    }
  }

  // searchTermFunction
  const doSearch = (searchTerm, queryTerm) => {
    async function getResults() {
      if (queryString !== null) {
        const results = await fetchApi({
          query: queryTerm,
          searchTerm: searchTerm,
          queryString: `/?${queryString}`,
        })

        setSearchResults(results)
      }
    }

    if (searchTerm !== '') {
      getResults()
    } else {
      if (productOrCollection === 'products') {
        setSearchResults(allProducts.current)
      } else if (productOrCollection === 'collection') {
        setSearchResults(allCollections.current)
      }
    }
  }

  return (
    <div className='two-columns'>
      {currentDeal?.type === 'spend' ? (
        <>
          <CurrentProducts products={currentDeal.products} handleRemoveProduct={handleRemoveProduct} headingCopy='Product exclusions' />
          <ProductSelection
            doSearch={doSearch}
            searchResults={searchResults}
            productOrCollection={productOrCollection}
            switchProductOrCollection={switchProductOrCollection}
            handleAddProduct={addProduct}
            handleAddCollection={addCollection}
            currentProducts={[...currentDeal.products]}
            operativeCopy='exclude it from the deal'
            dealType={currentDeal.type}
          />
        </>
      ) : (
        <>
          <CurrentProducts products={currentDeal.products} handleRemoveProduct={handleRemoveProduct} headingCopy='In this deal' />
          <ProductSelection
            doSearch={doSearch}
            searchResults={searchResults}
            productOrCollection={productOrCollection}
            switchProductOrCollection={switchProductOrCollection}
            handleAddProduct={addProduct}
            handleAddCollection={addCollection}
            currentProducts={[...currentDeal.products, ...otherDealProducts.current]}
            operativeCopy='add it to the deal'
            dealType={currentDeal.type}
          />
        </>
      )}
    </div>
  )
}

export default ListContainer
