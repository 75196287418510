import { useEffect, useState } from 'react'

const FreeGiftSettings = ({ changeEvent, activeProducts }) => {
  const updateProduct = (e, type, product) => {
    changeEvent({
      ...product,
      [type]: e.target.value,
    })
  }

  return (
    <>
      {activeProducts && activeProducts.length > 0 ? (
        activeProducts.map(product => (
          <div className='free-gift-product' key={product.id}>
            <img src={product.image} alt={product.title} />
            <div className='free-gift-form'>
              <h3>{product.title}</h3>
              <fieldset>
                <label htmlFor='gift-label'>Cart label</label>
                <input type='text' id='gift-label' value={product?.gift_label} onChange={e => updateProduct(e, 'gift_label', product)} />
              </fieldset>
              <fieldset>
                <label htmlFor='gift-threshold'>Threshold in £/€'</label>
                <input type='number' id='gift_threshold' min='10' max='200' value={product?.gift_threshold} step='1' onChange={e => updateProduct(e, 'gift_threshold', product)} />
              </fieldset>
            </div>
          </div>
        ))
      ) : (
        <p>Add some free gift products below</p>
      )}
    </>
  )
}

export default FreeGiftSettings
